import type { AnalyticFields } from "@octopusdeploy/portal-analytics";
import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "../page/pageId";
import { usePage } from "../page/useSetPageEffect";
export type PageEventDispatcher = (name: string, eventFields: AnalyticFields) => void;
export interface PageFields {
    "Page Area": string;
    "Page Name": string;
}
export function getPageFields(page: PageDefinition): PageFields {
    // Strip any " (Keywords)" from the Area string.
    return {
        "Page Area": page?.Area.replace(/ *\(.*\)/, ""),
        "Page Name": page?.Name,
    };
}
export function usePageEventDispatcher(): PageEventDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, eventFields: AnalyticFields) => {
        if (!page) {
            return;
        }
        const fields: PageFields = {
            ...getPageFields(page),
            ...eventFields,
        };
        session.track(name, fields);
    };
}
