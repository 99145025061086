import { css } from "@emotion/css";
import type { DropdownButtonState } from "@octopusdeploy/design-system-components";
import { navigationBarTextLinkStyles, navigationBarIconButtonStyles, ChevronUpIcon, ChevronDownIcon, useIsUrlActive, useOctopusLinkComponent } from "@octopusdeploy/design-system-components";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import type { ProjectGroupResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import { ErrorAlert } from "../Alert/ErrorAlert";
import { DropdownButton } from "../DropdownButton";
import type { ProjectSummary } from "./ProjectSummary";
import { ProjectsPanel } from "./ProjectsPanel";
import { useDispatchProjectSwitcherInteraction, projectSwitcherInteraction, useTrackProjectSelectedCallback } from "./analytics/useDispatchProjectSwitcherInteraction";
export interface ControlledProjectSwitcherDropdownProps {
    dropdownButtonState: DropdownButtonState;
    projectsState: ProjectsState;
    projectGroupMap: ResourcesById<ProjectGroupResource>;
    onFilterChange: (value: string) => void;
    isLoading: boolean;
    projectsHref: LinkHref;
    getProjectHref: (project: ProjectSummary) => LinkHref;
    onFetchProjectsRequested: () => void;
    error: Error | undefined;
    onCloseError: () => void;
}
export function ControlledProjectSwitcher({ dropdownButtonState, projectsState, projectGroupMap, onFilterChange, isLoading, projectsHref, getProjectHref, onFetchProjectsRequested, error, onCloseError }: ControlledProjectSwitcherDropdownProps) {
    const { isOpen, subscribeOpen, subscribeClose, closeDropdown } = dropdownButtonState;
    const dispatchProjectSwitcherInteraction = useDispatchProjectSwitcherInteraction();
    const trackProjectSelected = useTrackProjectSelectedCallback();
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isProjectLinkActive = isUrlActive(projectsHref, false);
    React.useEffect(() => {
        if (isOpen) {
            onFetchProjectsRequested();
        }
    }, [isOpen, onFetchProjectsRequested]);
    React.useEffect(() => subscribeOpen(() => dispatchProjectSwitcherInteraction(projectSwitcherInteraction.open)), [subscribeOpen, dispatchProjectSwitcherInteraction]);
    React.useEffect(() => subscribeClose(() => dispatchProjectSwitcherInteraction(projectSwitcherInteraction.close)), [subscribeClose, dispatchProjectSwitcherInteraction]);
    const handleProjectClicked = React.useCallback(() => {
        trackProjectSelected();
        closeDropdown();
    }, [closeDropdown, trackProjectSelected]);
    const errorAlert = error ? <ErrorAlert error={error} onClose={onCloseError} accessibleName="Project switcher error"/> : undefined;
    return (<div className={projectSwitcherStyles}>
            <Link className={projectSwitcherLinkStyles} href={projectsHref} aria-current={isProjectLinkActive ? "page" : undefined}>
                Projects
            </Link>
            <DropdownButton.Button className={projectSwitcherDropdownButtonStyles} dropdownButtonState={dropdownButtonState} accessibleName="Project switcher">
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </DropdownButton.Button>
            {isOpen && (<DropdownButton.Dropdown dropdownButtonState={dropdownButtonState} accessibleName="Project switcher dialog">
                    <ProjectsPanel projectsState={projectsState} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} onFilterChange={onFilterChange} isLoading={isLoading} errorAlert={errorAlert} onProjectClick={handleProjectClicked}/>
                </DropdownButton.Dropdown>)}
        </div>);
}
export interface RecentAndAllProjectsState {
    type: "recently-viewed-and-all";
    recentProjects: ProjectSummary[];
    allProjectsPageOne: ProjectSummary[];
    totalProjects: number;
}
export interface EmptyProjectsState {
    type: "empty";
    onAddNewProjectRequested: () => void;
    hasProjectCreatePermission: boolean;
}
export interface FilteredProjectsState {
    type: "filtered";
    filteredProjects: ProjectSummary[];
    totalProjects: number;
}
export interface InitiallyLoading {
    type: "initially-loading";
    recentProjects: ProjectSummary[];
}
export type ProjectsState = RecentAndAllProjectsState | EmptyProjectsState | FilteredProjectsState | InitiallyLoading;
const projectSwitcherStyles = css({
    display: "flex",
    // The project switcher has visual glitch where it can display some blue artifacts near the rounded corners after hovering.
    // This is likely a bug in Chrome/WebKit and only seems to happen in portal.
    // This can be replicated by opening portal, hovering over the button a few times, then change tabs and change back.
    // This is some magic CSS that forces this element to be rendered on its own rendering layer which bypasses this issue.
    backfaceVisibility: "hidden",
});
const projectSwitcherLinkStyles = css(navigationBarTextLinkStyles, {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: `1px solid ${colorScales.blue[700]}`,
});
const projectSwitcherDropdownButtonStyles = css(navigationBarIconButtonStyles, {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
});
