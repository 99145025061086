/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import { TriggerFilterResource, TriggerActionType, TriggerActionResource, TriggerFilterType } from "./triggerResource";
export class TriggerFeedFilterResource extends TriggerFilterResource {
    constructor(packages: DeploymentActionPackageResource[]) {
        super();
        this.FilterType = TriggerFilterType.FeedFilter;
        this.Packages = packages;
    }
    Packages: DeploymentActionPackageResource[] = [];
}
export class CreateReleaseActionResource extends TriggerActionResource {
    ChannelId: string = undefined!;
    ActionType: TriggerActionType = TriggerActionType.CreateRelease;
}
