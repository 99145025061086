import type { Client } from "../client";
import type { DeploymentFreezeOverviewBffResponse } from "../resources/deploymentFreezeOverviewBffResponse";
export class DeploymentFreezesRepository {
    protected client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getOverviewBff() {
        return this.client.get<DeploymentFreezeOverviewBffResponse>("~/bff/deploymentfreezes/overview");
    }
}
