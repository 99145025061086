import type { Client } from "../client";
import type { GitCommit, GitRefResource, ProjectResource, ReleaseResource, ReleaseTemplateResource } from "../resources";
import type { DeploymentProcessResource, ModifyDeploymentProcessCommand } from "../resources/deploymentProcessResource";
type GetRawDeploymentProcessOclResponseV1 = {
    Ocl: string;
};
type ModifyRawDeploymentProcessOclResponseV1 = {
    Commit?: GitCommit;
};
export class DeploymentProcessRepository {
    readonly resourceLink = "DeploymentProcess";
    readonly collectionLink = "DeploymentProcesses";
    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly gitRef: GitRefResource | undefined) {
        this.client = client;
    }
    get(): Promise<DeploymentProcessResource> {
        if (this.project.IsVersionControlled && this.gitRef !== undefined) {
            return this.client.get(this.gitRef.Links[this.resourceLink]);
        }
        return this.client.get(this.project.Links[this.resourceLink]);
    }
    getForRelease(release: ReleaseResource): Promise<DeploymentProcessResource> {
        return this.client.get<DeploymentProcessResource>(this.client.getLink(this.collectionLink), { id: release.ProjectDeploymentProcessSnapshotId });
    }
    getTemplate(deploymentProcess: DeploymentProcessResource, channelId: string, releaseId: string) {
        return this.client.get<ReleaseTemplateResource>(deploymentProcess.Links["Template"], { channel: channelId, releaseId });
    }
    getTemplateForGitRef(gitRef: GitRefResource, channelId: string) {
        return this.client.get<ReleaseTemplateResource>(gitRef.Links["ReleaseTemplate"], { channel: channelId });
    }
    getRawOcl() {
        return this.client.get<GetRawDeploymentProcessOclResponseV1>("~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/deploymentprocesses/raw", { spaceId: this.project.SpaceId, projectId: this.project.Id, gitRef: this.gitRef?.CanonicalName });
    }
    modifyRawOcl(ocl: string, commitMessage?: string) {
        return this.client.post<ModifyRawDeploymentProcessOclResponseV1>("~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/deploymentprocesses/raw", {
            spaceId: this.project.SpaceId,
            projectId: this.project.Id,
            gitRef: this.gitRef?.CanonicalName,
            ocl,
            changeDescription: commitMessage,
        }, {
            spaceId: this.project.SpaceId,
            projectId: this.project.Id,
            gitRef: this.gitRef?.CanonicalName,
        });
    }
    async modify(deploymentProcess: ModifyDeploymentProcessCommand): Promise<DeploymentProcessResource> {
        const updatedDeploymentProcess = await this.client.update(deploymentProcess.Links.Self, deploymentProcess);
        this.client.dispatchEvent({ type: "DeploymentProcessModified" });
        return updatedDeploymentProcess;
    }
}
